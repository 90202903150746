
import { reactive } from 'vue'
import _list from '@/assets/js/list'
import _model from '@/static/model/account/bill'
import Bill from '@/views/serviceAccount/order.vue'

export default {
	components: {Bill},
	setup(props, context) {
		return {
			_model: _model,
			options: reactive({}),
			setting: reactive({
				moduleName: '结算单',
				searchApi: '/Settlement/Bill/settlementList',
				moduleType: 'full',
				tableFirstType: 'selection',
				orderVisible:false
			}),
			list: reactive({search: {mchType: 2}, data: []}),
		}
	},
	mounted() {
		this._load(this, _list)
		this._listSearch()
	},
	methods: {
		
		//账单数量
		showOrder: function(index, settlementId){
			this.setting.BillEndTime = this.list.search.endTime
			this.setting.BillmchType = this.list.search.mchType
			this.setting.BillsettlementId = settlementId
			this.setting.orderVisible = true
		},
		
		//获取表单数据
		getPostData: function(settlementId, isExport){
			return {
				endTime: this.list.search.endTime,
				mchType: 2,
				settlementId: this.list.data.settlementId,
				export: isExport?isExport:false
			}
		},
		//导出明细
		exportXls: function(settlementId, mchName){
			// let data = this.getPostData(settlementId)
			let fileName = mchName + ' '  + ' 账单明细.csv'
			if(!mchName) fileName = ' 账单明细.csv'
			let postData = {
				settlementId: settlementId,
				export: true
			}
			// postData.search.export = true
			this._download('/Settlement/Bill/getMchBillBySltId', postData, null, fileName)
		},
		
	}
}
